<template>
  <v-btn v-bind="$attrs" v-on="$listeners" :color="color" :class="{'white--text o-btn': !isDefault}"><slot /></v-btn>
</template>

<script lang="ts">
import Vue from "vue";

const variantMap: Record<string, string> = {
  "0": "default",
  "1": "primary",
  "2": "secondary",
  "3": "tertiary"
};

export default Vue.extend({
  props: {
    variant: {
      type: String,
      default: "primary",
      validator: v => ["default", "primary", "secondary", "tertiary", "0", "1", "2", "3"].includes(v)
    }
  },
  computed: {
    color(): string | undefined {
      if (this.isDefault) return undefined;
      return variantMap[this.variant] ?? this.variant;
    },
    isDefault(): boolean {
      return ((variantMap[this.variant] ?? this.variant) == "default");
    }
  }
});
</script>
