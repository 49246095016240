<template>
  <v-dialog eager @input="change" value="true" :max-width="width" :persistent="persistent" @keydown.esc="choose(null)">
    <v-card id="confirmTile" class="pa-2 confirm-dlg-content">
      <v-toolbar v-if="Boolean(title)" dense flat>
        <v-icon v-if="Boolean(icon)" color="primary" :size="20" left>{{ icon }}</v-icon>
        <v-toolbar-title class="text-h6 font-weight-bold" :class="Boolean(color) ? `${color}--text` : undefined" v-text="title" />
        <v-spacer />
        <v-btn id="btnClose" icon @click="choose(null)">
          <v-icon color="primary">$close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="px-4 text-body-1 text--darken-1 font-weight-bold" :class="Boolean(title) ? 'pb-3': 'py-3'" v-html="message" />
      <v-card-actions class="confirm-btn-actions">
        <v-spacer />
        <o-btn v-if="Boolean(buttonFalseText)" class=" px-4 w-100-mb" :class="{'mr-2' : !isMobile}" :color="buttonFalseColor" :outlined="!buttonTrueFlat" @click="choose(false)" id="btnConfirmNo">
          <v-icon v-if="buttonFalseIcon" class="mr-2" small>{{ buttonFalseIcon }}</v-icon>{{ buttonFalseText }}
        </o-btn>
        <copy-content-btn
          v-if="!!copyContent && copyContent.length"
          id="btnCopyVideoLink"
          :content="copyContent"
          :caption="buttonTrueText"
          :title="buttonTrueText"
          :small="false"
          :successTxt="copySuccessText"
          :failureTxt="copyFailureText"
          @click="choose(true)"
        />
        <o-btn
          v-else-if="Boolean(buttonTrueText)"
          class="px-4 custom-confirm-btn w-100-mb"
          :class="{'mb-4 mx-0' : isMobile}"
          id="btnConfirmYes"
          :color="buttonTrueColor"
          :text="buttonTrueFlat"
          @click="choose(true)"
        >
          <v-icon v-if="buttonTrueIcon" class="mr-2" small>{{ buttonTrueIcon }}</v-icon>{{ buttonTrueText }}
        </o-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import OBtn from "@/framework/OBtn.vue";
import CopyContentBtn from "@/components/objects/CopyContentBtn.vue";

export default Vue.extend({
  components: {
    OBtn,
    CopyContentBtn
  },
  props: {
    buttonTrueText: {
      type: String,
      default: "Yes"
    },
    buttonFalseText: {
      type: String,
      default: "No"
    },
    buttonTrueIcon: {
      type: String,
      required: false
    },
    buttonFalseIcon: {
      type: String,
      required: false
    },
    buttonTrueColor: {
      type: String,
      default: "primary"
    },
    buttonFalseColor: {
      type: String,
      default: "primary"
    },
    buttonFalseFlat: {
      type: Boolean,
      default: false
    },
    buttonTrueFlat: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "primary"
    },
    icon: {
      type: String,
      default() {
        return "$warning";
      }
    },
    message: {
      type: String,
      required: true
    },
    persistent: {
      type: Boolean,
      default: true
    },
    title: {
      type: String
    },
    width: {
      type: Number,
      default: 500
    },
    copyContent: {
      type: String,
      required: false
    },
    copySuccessText: {
      type: String,
      default: "Content copied to Clipboard."
    },
    copyFailureText: {
      type: String,
      default: "Could not copy content to clipboard."
    }
  },
  data() {
    return {
      value: false as boolean | null
    };
  },
  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    onEnterPressed(e: any) {
      if (e.keyCode === 13) {
        e.stopPropagation();
        this.choose(true);
      }
    },
    choose(value: boolean | null) {
      this.$emit("result", value);
      this.value = value;
      this.$destroy();
    },
    change() {
      this.$destroy();
    },
    dismiss() {
      this.choose(false);
    }
  },
  mounted() {
    document.addEventListener("keyup", this.onEnterPressed);
  },
  destroyed() {
    document.removeEventListener("keyup", this.onEnterPressed);
  },
  created() {
    this.$eventBus.$on("dismiss", this.dismiss);
  },
  beforeDestroy() {
    this.$eventBus.$off("dismiss", this.dismiss);
  }
});
</script>

<style lang="scss" scoped>
@import "@/scss/local.scss";
.v-card {
  border-radius: $o-border-radius;
}
@media (max-width: 960px) {
  .confirm-btn-actions {
    flex-direction: column-reverse;
  }

  .w-100-mb {
    width: 100%;
  }
}

</style>
