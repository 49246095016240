/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Report Header
 */
export type ReportHeaderRecordItem = {
    /**
     * Record Unique ID
     */
    id?: number;
    /**
     * User ID
     */
    userId?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    created?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    updated?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    lastRun?: number;
    /**
     * awsUrl for report
     */
    awsUrl?: string;
    /**
     * Report Type
     */
    reportType?: ReportHeaderRecordItem.reportType;
    /**
     * Flag for record being active
     */
    active?: boolean;
    /**
     * List of pool names
     */
    poolNames?: string;
    /**
     * List of plan names
     */
    planNames?: string;
    /**
     * List of query ID
     */
    queryIds?: string;
    /**
     * List of grant names
     */
    grantNames?: string;
    /**
     * Count of grant ID
     */
    countGrant?: string;
    /**
     * Count of pool ID
     */
    countPool?: string;
    /**
     * Count of plan ID
     */
    countPlan?: string;
    /**
     * Count of query ID
     */
    countQuery?: string;
}

export namespace ReportHeaderRecordItem {

    /**
     * Report Type
     */
    export enum reportType {
        EXECUTIVE = 'executive',
        TOPLINE = 'topline',
        GRAPH = 'graph',
        VERBATIM = 'verbatim',
    }


}
