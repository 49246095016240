import Vue from "vue";

// Use the following to enable tree-shaking - causes some linter issues
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

// Localization is with vue-i18n without integrating vuetify localization, which will handle their own
//If we need to customize the vue messages for additional languages or just customize them, then we
// should integrate the language files into vue-18n. See https://stackoverflow.com/a/59823557/6728516

export default new Vuetify({
  theme: {
    options: {
      // Define css variable for the theme colors, that we can use in the SFCs
      customProperties: true
    },
    themes: {
      light: {
        // Main theme colors and accents
        primary: "#754199",
        secondary: "#8C377D",
        tertiary: "#664D87",

        // Pill COlor/Highlight on Time Picker
        accent1: "#EBDAF7",
        accent2: "#F&DAF2",
        accent3: "#EBDAF7",

        accent: "#ab98c2", //"#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#754199", // "#4CAF50",
        warning: "#FFC107",

        background: "#F0EDF5",
        cellborder: "#e5e5e5",
        memberFrameBackground: "#F1EAF4", // Per EFavia

        ethansFavorite: "#FF0000"
      }
    }
  },
  // For our custom font vuetify parses out the base class automatically - it cen-house - vuetify will include cen and cen-house in the styles
  icons: {
    iconfont: "fa", // Fall back for fontawesome
    values: {
      email: "fas fa-envelope",
      phone: "fas fa-phone",
      password: "fas fa-key",

      // App Bar
      language: "fas fa-language",
      // Rail
      //home: "fas fa-home",
      home: "cen-house",
      account: "fas fa-user",
      support: "fas fa-life-ring",
      //discussions: "fas fa-comment-medical",
      discussions: "cen cen-chat",
      admin: "fas fa-tools",
      logout: "fas fa-sign-out-alt",

      // Other
      calendar: "fa fa-calendar",
      back: "fa fa-arrow-left",
      dismiss: "fas fa-window-close",
      clock: "far fa-clock",
      image: "far fa-image",
      imageRotate: "fas fa-sync-alt", 
      globe: "fas fa-globe",
      location: "fas fa-map-marker-alt",
      circleCheck: "far fa-circle-check",
      expandLeft: "fas fa-chevron-left",
      expandRight: "fas fa-chevron-right",
      solidChevronRight: "fa-solid fa-chevron-right",
      solidChevronDown: "fa-solid fa-chevron-down",

      // FABs
      plus: "fas fa-plus",
      minus: "fas fa-minus",

      // item menu
      options: "fas fa-ellipsis-v",
      copy: "far fa-copy",
      edit: "far fa-edit",

      // Account
      user: "far fa-user",
      userDisabled: "far fa-user-slash",
      accountInfo: "cen cen-accountinfo",
      profilePic: "cen cen-user",
      contactInfo: "cen cen-contactinfo",
      addressInfo: "cen cen-addressinfo",
      notifications: "cen cen-notifications",
      compensation: "cen cen-userbank",
      specialities: "cen cen-specialties",
      vacationTime: "cen cen-vacationtime",
      myBio: "cen cen-mybio",
      testUser: "fas fa-cat-space",
      camera: "fas fa-camera",
      remove: "far fa-trash-can",

      // boolean - i.e active flag
      flagTrue: "fas fa-check green--text",
      flagFalse: "fas fa-times red--text",

      // Query
      textAttachment: "cen cen-chat",
      videoAttachment: "cen cen-noeticlive",
      audioAttachment: "cen cen-phone",
      csrHold: "cen cen-csrhold",
      blinded: "cen cen-blinded",
      pendingExpert: "cen cen-pendingexpert",
      requested: "cen cen-requested",
      createInReadOnly: "cen cen-createinreadonly",
      readOnly: "cen cen-responsepostresponses",

      // Util
      excel: "far fa-file-excel",
      expertDetail: "fas fa-user-md",
      diagnoses: "fas fa-diagnoses",
      sort: "fas fa-sort-down",
      download: "fas fa-download",
      lock: "fas fa-lock",
      link: "fa-solid fa-link",
      "user-plus": "fa-solid fa-user-plus",
      "user-minus": "fa-solid fa-user-minus",
      info: "far fa-info-circle",
      infoSolid: "fas fa-circle-exclamation",
      infoBubble: "fa-regular fa-circle-info",
      toggleChevronLeft: "fa-solid fa-circle-chevron-left",
      toggleChevronRight: "fa-solid fa-circle-chevron-right",
      
      // filter bar
      filter: "fas fa-search",
      help: "far fa-question-circle",
      reply: "fal fa-reply",
      eyeLight: "fal fa-eye",

      // Discussion Screen
      print: "fas fa-print",
      printCsr: "fas fa-print-search",
      zoom: "fas fa-search-plus",
      eye: "fas fa-eye",
      eyeSlash: "fas fa-eye-slash",
      linkout: "fas fa-external-link-alt",
      pdf: "fas fa-file-pdf",
      send: "fas fa-paper-plane",
      sendSpin: "fas fa-paper-plane fa-spin",
      paperclip: "fas fa-paperclip",
      comment: "far fa-comment",
      ellipsis: "fas fa-ellipsis-h",
      chevron: "fas fa-chevron-down",
      thumbsUp: "far fa-thumbs-up",
      thumbsDown: "far fa-thumbs-down",
      popout: "fas fa-expand-alt",
      envalope: "fal fa-envelope",
      flag: "far fa-flag",
      flagFilled: "fas fa-flag",
      screen: "far fa-presentation-screen",
      downloadArrow: "fa-sharp fa-solid fa-file-arrow-down",
      trash: "fa-solid fa-trash-can",
      verticalKebabIcon: "fa-solid fa-ellipsis-vertical",

      //Query Index
      participants: "fas fa-user-friends",
      responses: "fas fa-comment-alt",

      // Invitees 
      pitchOn: "fa-solid fa-microphone",
      pitchUp: "fa-solid fa-arrow-up",
      pitchDown: "fa-solid fa-arrow-down",
      pitchMute:"fa-solid fa-microphone-slash",

      // Tag scope
      team: "cen cen-team",
      global: "cen cen-global",

      // MEDIA Controls
      play: "fas fa-play",
      pause: "fas fa-pause",
      stop: "fas fa-stop",
      volumeUp: "fas fa-volume-up",
      volumeMute: "fas fa-volume-mute",
      refresh: "fas fa-sync",
      refreshFilter: "fa-solid fa-arrows-rotate",

      // Pro icons
      editCalendar: "far fa-calendar-pen",
      downloadCalendar: "far fa-calendar-arrow-down",
      queryReview: "far fa-memo-circle-check",

      // Question Builder
      saveRotateArrow: "fa-solid fa-arrows-rotate",
      saveCheck: "fa-regular fa-square-check",
      addMore: "fa-sharp fa-solid fa-circle-plus"

    },
  },
});
