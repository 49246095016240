/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '../core/request';

export class SupportService {

    /**
     * Create a new Support Email
     * Creates a comm record and sends an email
     * @result any Successful operation
     * @throws ApiError
     */
    public static async createSupportEmail({
        requestBody,
    }: {
        /** Email Subject **/
        requestBody: {
            /**
             * Email Body
             */
            emailBody: string,
        },
    }): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/server/support/email`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

}