<template>
  <v-dialog eager @input="change" value="true" :max-width="width" :persistent="persistent" @keydown.esc="choose(false)">
    <v-card class="pa-2 alert-dlg-content">
      <v-toolbar v-if="Boolean(title)" dense flat>
        <v-icon v-if="Boolean(icon)" color="primary" :size="20" left>{{ icon }}</v-icon>
        <v-toolbar-title class="text-h6 font-weight-bold" :class="Boolean(color) ? `${color}--text` : undefined" v-text="title" />
        <v-spacer />
        <v-btn id="btnClose" icon @click="choose(false)">
          <v-icon color="primary">$close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="py-3 px-4 alert-block-text t-2 text--darken-1 font-weight-bold" v-html="message" />
      <v-card-actions>
        <v-spacer />
        <o-btn v-if="Boolean(dismissText)" class="px-4" :color="dismissColor" :text="dismissTextFlat" @click="choose(true)">
          {{ dismissText }}
        </o-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import OBtn from "@/framework/OBtn.vue";

export default Vue.extend({
  components: {
    OBtn
  },
  props: {
    dismissText: {
      type: String,
      default: "OK"
    },
    dismissColor: {
      type: String,
      default: "primary"
    },
    dismissTextFlat: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: "primary"
    },
    icon: {
      type: String,
      default() {
        return "$warning";
      }
    },
    message: {
      type: String,
      required: true
    },
    persistent: {
      type: Boolean,
      default: true
    },
    title: {
      type: String
    },
    width: {
      type: Number,
      default: 450
    }
  },
  data() {
    return {
      value: false
    };
  },
  methods: {
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    onEnterPressed(e: any) {
      if (e.keyCode === 13) {
        e.stopPropagation();
        this.choose(true);
      }
    },
    choose(value: boolean) {
      this.$emit("result", value);
      this.value = value;
      this.$destroy();
    },
    change() {
      this.$destroy();
    },
    dismiss() {
      this.choose(false);
    }
  },
  mounted() {
    document.addEventListener("keyup", this.onEnterPressed);
  },
  destroyed() {
    document.removeEventListener("keyup", this.onEnterPressed);
  },
  created() {
    this.$eventBus.$on("dismiss", this.dismiss);
  },
  beforeDestroy() {
    this.$eventBus.$off("dismiss", this.dismiss);
  }
});
</script>

<style lang="scss" scoped>
@import "@/scss/local.scss";
.v-card {
  border-radius: $o-border-radius;
}

.alert-block-text {
  font-family: Arial, Helvetica, sans-serif;
}
</style>
