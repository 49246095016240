/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MembershipCensusItem } from '../models/MembershipCensusItem';
import type { MembershipCensusRecordItem } from '../models/MembershipCensusRecordItem';
import type { MembershipLinkItem } from '../models/MembershipLinkItem';
import type { MembershipPlanListItem } from '../models/MembershipPlanListItem';
import type { MembershipPlanRecordItem } from '../models/MembershipPlanRecordItem';
import type { MembershipPlanSummaryInfo } from '../models/MembershipPlanSummaryInfo';
import type { MembershipPlanSurgeItem } from '../models/MembershipPlanSurgeItem';
import type { meta } from '../models/meta';
import type { UserReportingInfo } from '../models/UserReportingInfo';
import { request as __request } from '../core/request';

export class MembershipService {

    /**
     * Create a new plan link
     * Creates a record based on data given
     * @result MembershipLinkItem Successful operation
     * @throws ApiError
     */
    public static async createPlanLink({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Link record to be created. Plan id is ignored. **/
        requestBody: MembershipLinkItem,
    }): Promise<MembershipLinkItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/membership/plan/${id}/link`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * List surge payment periods for a specified plan
     * Returns Plan Surge records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getMembershipSurges({
        id,
        includePast = false,
        activeOnly = true,
        page = 1,
        pageSize = 10,
    }: {
        /** Membership Plan ID **/
        id: number,
        /** Include Past Surges **/
        includePast?: boolean,
        /** Records to Include based on Active Flag. **/
        activeOnly?: boolean,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
    }): Promise<{
        data?: Array<MembershipPlanSurgeItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/membership/plan/${id}/surge`,
            query: {
                'includePast': includePast,
                'activeOnly': activeOnly,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Create a new plan surge payment record
     * Creates a record based on data given
     * @result MembershipPlanSurgeItem Successful operation
     * @throws ApiError
     */
    public static async createPlanSurge({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Surge record to be created. Plan id is ignored. **/
        requestBody: MembershipPlanSurgeItem,
    }): Promise<MembershipPlanSurgeItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/membership/plan/${id}/surge`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update an existing plan link
     * Updates a record based on data given
     * @result MembershipLinkItem Successful operation
     * @throws ApiError
     */
    public static async updatePlanLink({
        id,
        linkId,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** ID of link to update **/
        linkId: number,
        /** Link record to be updated. IDs are ignored. **/
        requestBody: MembershipLinkItem,
    }): Promise<MembershipLinkItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/membership/plan/${id}/link/${linkId}`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Delete an existing plan link
     * Deletes the specified plan link
     * @result any Successful operation
     * @throws ApiError
     */
    public static async deletePlanLink({
        id,
        linkId,
    }: {
        /** Record ID **/
        id: number,
        /** ID of link to delete **/
        linkId: number,
    }): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/server/membership/plan/${id}/link/${linkId}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find census members by Membership Plan ID.
     * Returns matching record
     * @result any successful operation
     * @throws ApiError
     */
    public static async getPlanMembersList({
        id,
        activeOnly = true,
        page = 1,
        pageSize = 10,
        sort,
    }: {
        /** Membership Plan ID **/
        id: number,
        /** Records to Include based on Active Flag. **/
        activeOnly?: boolean,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
    }): Promise<{
        data?: Array<MembershipCensusItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/membership/plan/${id}/members/list`,
            query: {
                'activeOnly': activeOnly,
                'page': page,
                'pageSize': pageSize,
                'sort': sort,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find membership plan by ID.
     * Returns matching record
     * @result MembershipPlanRecordItem successful operation
     * @throws ApiError
     */
    public static async getMembershipPlanById({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<MembershipPlanRecordItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/membership/plan/${id}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update an existing membershipPlan
     * Update a record based on data given
     * @result MembershipPlanRecordItem Successful operation
     * @throws ApiError
     */
    public static async updateMembershipPlan({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** MembershipPlan object that needs to be updated **/
        requestBody: MembershipPlanRecordItem,
    }): Promise<MembershipPlanRecordItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/membership/plan/${id}`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find multiple membership plans.  Filterable by various.
     * Filterable by Name, Grant Id, Diagnosis Id, User Id or exclusions of same.  If UserId used will return user settings.
     * @result any successful operation
     * @throws ApiError
     */
    public static async getMembershipPlanList({
        planType,
        includePlanId,
        name,
        grantId,
        poolId,
        diagnosisDomainId,
        diagnosisId,
        userId,
        userType,
        activeOnly = true,
        activeNowOnly = true,
        excludeUserId,
        excludeDiagnosisId,
        excludeDiagnosisDomainId,
        excludePoolId,
        effectiveDateSearchStart,
        effectiveDateSearchEnd,
        excludeGrantId,
        page = 1,
        pageSize = 10,
        sort,
    }: {
        /** Type of plan **/
        planType?: 'census' | 'sso' | 'region' | 'advocate' | 'blinded' | 'expert' | 'vtb',
        /** Include a currently selected reord irrespective of filters  **/
        includePlanId?: number,
        /** Find records searching by Name.  Enter ID for specific record **/
        name?: string,
        /** Find records associated with a given Grant **/
        grantId?: number,
        /** Find records associated with a given Pool **/
        poolId?: number,
        /** Find records associated with a given Diagnosis Domain **/
        diagnosisDomainId?: number,
        /** Find records associated with a given Diagnosis  **/
        diagnosisId?: number,
        /** Find records associated with a given User **/
        userId?: number,
        /** Find records associated with a given User Type **/
        userType?: number,
        /** Records to Include based on Active Flag. **/
        activeOnly?: boolean,
        /** Include only records active now - will look at active flag and date ranges **/
        activeNowOnly?: boolean,
        /** Exclude records associated with a given User **/
        excludeUserId?: number,
        /** Exclude records associated with a given Diagnosis **/
        excludeDiagnosisId?: number,
        /** Exclude records associated with a given Diagnosis Domain **/
        excludeDiagnosisDomainId?: number,
        /** Exclude records associated with a given Pool **/
        excludePoolId?: number,
        /** Date Search Start.  Will return any records where effective date was active in range **/
        effectiveDateSearchStart?: number,
        /** Date Search End.  Will return any records where effective date was active in range **/
        effectiveDateSearchEnd?: number,
        /** Exclude records associated with a given Grant **/
        excludeGrantId?: number,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
    }): Promise<{
        data?: Array<MembershipPlanListItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/membership/plan/list`,
            query: {
                'planType': planType,
                'includePlanId': includePlanId,
                'name': name,
                'grantId': grantId,
                'poolId': poolId,
                'diagnosisDomainId': diagnosisDomainId,
                'diagnosisId': diagnosisId,
                'userId': userId,
                'userType': userType,
                'activeOnly': activeOnly,
                'activeNowOnly': activeNowOnly,
                'excludeUserId': excludeUserId,
                'excludeDiagnosisId': excludeDiagnosisId,
                'excludeDiagnosisDomainId': excludeDiagnosisDomainId,
                'excludePoolId': excludePoolId,
                'effectiveDateSearchStart': effectiveDateSearchStart,
                'effectiveDateSearchEnd': effectiveDateSearchEnd,
                'excludeGrantId': excludeGrantId,
                'page': page,
                'pageSize': pageSize,
                'sort': sort,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Retrieve a plan link by Id
     * Returns matching link
     * @result MembershipLinkItem successful operation
     * @throws ApiError
     */
    public static async getMembershipLinkById({
        id,
    }: {
        /** Plan Link ID **/
        id: number,
    }): Promise<MembershipLinkItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/membership/links/${id}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * List links for a specified plan
     * Returns matching links
     * @result any successful operation
     * @throws ApiError
     */
    public static async getMembershipLinks({
        id,
        page = 1,
        pageSize = 10,
        sort,
    }: {
        /** Membership Plan ID **/
        id: number,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
    }): Promise<{
        data?: Array<MembershipLinkItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/membership/plan/${id}/links`,
            query: {
                'page': page,
                'pageSize': pageSize,
                'sort': sort,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find plan member by Plan Id and User Id.
     * Returns matching record
     * @result MembershipCensusRecordItem successful operation
     * @throws ApiError
     */
    public static async getPlanMemberByPlanIdUserId({
        id,
        userId,
    }: {
        /** Record ID **/
        id: number,
        /** ID of member  **/
        userId: number,
    }): Promise<MembershipCensusRecordItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/membership/plan/${id}/member/${userId}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update a member in a plan
     * Updates a record based on data given
     * @result MembershipCensusRecordItem successful operation
     * @throws ApiError
     */
    public static async updatePlanMember({
        id,
        userId,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** ID of member to add to plan **/
        userId: number,
        /** Member Census object that needs to be updated **/
        requestBody: MembershipCensusRecordItem,
    }): Promise<MembershipCensusRecordItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/membership/plan/${id}/member/${userId}`,
            body: requestBody,
            errors: {
                400: `Error: Bad Request`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Add a member to a plan
     * Creates a record based on data given
     * @result MembershipCensusRecordItem successful operation
     * @throws ApiError
     */
    public static async addPlanMember({
        id,
        userId,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** ID of member to add to plan **/
        userId: number,
        /** Member Census object that needs to be updated **/
        requestBody: MembershipCensusRecordItem,
    }): Promise<MembershipCensusRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/membership/plan/${id}/member/${userId}`,
            body: requestBody,
            errors: {
                400: `Error: Bad Request`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Remove a Member from a Plan
     * Remove a record based on data given
     * @result MembershipCensusRecordItem successful operation
     * @throws ApiError
     */
    public static async removePlanMember({
        id,
        userId,
    }: {
        /** Record ID **/
        id: number,
        /** ID of member to remove from plan **/
        userId: number,
    }): Promise<MembershipCensusRecordItem> {
        const result = await __request({
            method: 'DELETE',
            path: `/server/membership/plan/${id}/member/${userId}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * List manager users for a specified advocate plan
     * Returns User records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getMembershipPlanManagers({
        id,
        page = 1,
        pageSize = 10,
    }: {
        /** Membership Plan ID **/
        id: number,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
    }): Promise<{
        data?: Array<UserReportingInfo>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/membership/plan/${id}/managers`,
            query: {
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Get Plan Summary Info
     * Returs plan summary information
     * @result MembershipPlanSummaryInfo successful operation
     * @throws ApiError
     */
    public static async getMembershipPlanSummary({
        id,
        maxSubRecords = 5,
    }: {
        /** Record ID **/
        id: number,
        /** Number of sub records to return.  Default 5 max 20. **/
        maxSubRecords?: number,
    }): Promise<MembershipPlanSummaryInfo> {
        const result = await __request({
            method: 'GET',
            path: `/server/membership/plan/${id}/summary`,
            query: {
                'maxSubRecords': maxSubRecords,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Create a new membershipPlan
     * Creates a record based on data given
     * @result MembershipPlanRecordItem Successful operation
     * @throws ApiError
     */
    public static async createMembershipPlan({
        requestBody,
    }: {
        /** MembershipPlan object to be created **/
        requestBody: MembershipPlanRecordItem,
    }): Promise<MembershipPlanRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/membership/plan`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Add a diagnosis to a plan
     * Creates a record based on data given
     * @result any Successful operation
     * @throws ApiError
     */
    public static async addPlanDiagnosis({
        id,
        diagnosisId,
    }: {
        /** Record ID **/
        id: number,
        /** ID of diagnosis to add to plan **/
        diagnosisId: number,
    }): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/server/membership/plan/${id}/diagnosis/${diagnosisId}`,
            errors: {
                400: `Error: Bad Request`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Remove a diagnosis from a plan
     * Remove a record based on data given
     * @result any Successful operation
     * @throws ApiError
     */
    public static async deletePlanDiagnosis({
        id,
        diagnosisId,
    }: {
        /** Record ID **/
        id: number,
        /** ID of diagnosis to remove from plan **/
        diagnosisId: number,
    }): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/server/membership/plan/${id}/diagnosis/${diagnosisId}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Add a domain to a plan
     * Creates a record based on data given
     * @result any Successful operation
     * @throws ApiError
     */
    public static async addPlanDomain({
        id,
        domainId,
    }: {
        /** Record ID **/
        id: number,
        /** ID of domain to add to plan **/
        domainId: number,
    }): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/server/membership/plan/${id}/domain/${domainId}`,
            errors: {
                400: `Error: Bad Request`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Remove a domain from a plan
     * Remove a record based on data given
     * @result any Successful operation
     * @throws ApiError
     */
    public static async deletePlanDomain({
        id,
        domainId,
    }: {
        /** Record ID **/
        id: number,
        /** ID of domain to remove from plan **/
        domainId: number,
    }): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/server/membership/plan/${id}/domain/${domainId}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Add a grant to a plan
     * Creates a record based on data given
     * @result any Successful operation
     * @throws ApiError
     */
    public static async addPlanGrant({
        id,
        grantId,
    }: {
        /** Record ID **/
        id: number,
        /** ID of grant to add to plan **/
        grantId: number,
    }): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/server/membership/plan/${id}/grant/${grantId}`,
            errors: {
                400: `Error: Bad Request`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Remove a grant from a plan
     * Remove a record based on data given
     * @result any Successful operation
     * @throws ApiError
     */
    public static async deletePlanGrant({
        id,
        grantId,
    }: {
        /** Record ID **/
        id: number,
        /** ID of grant to remove from plan **/
        grantId: number,
    }): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/server/membership/plan/${id}/grant/${grantId}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Add a manager to a plan
     * Creates a record based on data given
     * @result any Successful operation
     * @throws ApiError
     */
    public static async addPlanManager({
        id,
        userId,
    }: {
        /** Record ID **/
        id: number,
        /** ID of manager to add to plan **/
        userId: number,
    }): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/server/membership/plan/${id}/manager/${userId}`,
            errors: {
                400: `Error: Bad Request`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Remove a manager from a plan
     * Remove a record based on data given
     * @result any Successful operation
     * @throws ApiError
     */
    public static async deletePlanManager({
        id,
        userId,
    }: {
        /** Record ID **/
        id: number,
        /** ID of manager to remove from plan **/
        userId: number,
    }): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/server/membership/plan/${id}/manager/${userId}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update an existing  plan surge payment record
     * Updates a record based on data given
     * @result MembershipPlanSurgeItem Successful operation
     * @throws ApiError
     */
    public static async updatePlanSurge({
        id,
        surgeId,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** ID of surge record to update **/
        surgeId: number,
        /** Surge record to be updated. Plan id is ignored. **/
        requestBody: MembershipPlanSurgeItem,
    }): Promise<MembershipPlanSurgeItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/membership/plan/${id}/surge/${surgeId}`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

}